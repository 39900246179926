@import "Styles/colors.module.scss";

.chatContainer {
    .questionAnswerPair {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    .qaBox {
        border-radius: 4px;
        padding: 10px 16px;
        color: $main-text-color;
        font-size: 1rem;
        line-height: 1.625rem;
        letter-spacing: 0.496px;

        &.loadingBox {
            padding: 16px;
        }
    }
    .question {
        background-color: #99CAFF; // == same with rgba(0, 122, 255, 0.4); but this looks different in the browser
    }
    .answer {
        margin: 8px 0;
        background-color: #E5F1FF; // == same with rgba(0, 122, 255, 0.1); but this looks different in the browser
    }
}