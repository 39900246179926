@import "Styles/colors.module.scss";

.filters {
  .filtersButton {
    flex-direction: row;
    gap: 8px;
    background-color: $white;
    border: 1px solid $findest-gray;
    text-transform: none;

    &:hover {
      background-color: $white;
    }

    svg {
      margin: 0;
    }

    .filtersApplied {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      background-color: $light-gray;
      border-radius: 50%;
    }
  }

  .popover {
    padding: 0.8rem;
    width: 200px;

    .container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        font-size: .75rem;
        font-weight: 700;
        color: $findest-dark-gray;
        height: 1rem;
      }

      .inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 180px;

        svg {
          color: $notification-red;
        }

        .inputContainer {
          width: 76px;
          border: 1px solid $findest-gray;
          border-radius: .25rem;
          text-align: center;

          &.inputContainerBorder {
            border-color: $notification-red;
          }
          
          input[type="number"] {
            width: 48px;
            height: 40px;
            text-align: center;
            border: none;
            appearance: none;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
  
            &:focus {
              outline: none;
              border: none;
            }
            font-size: .875rem;
          }
        }


        .seperator {
          width: 20px;
          height: 40px;
          margin-bottom: -40px;
          text-align: center;
        }
      }

      .message {
        height: fit-content;
        color: $main-dark-red;
        margin: .5rem 0;
      }

      .checkboxContainer {
        .text {
          font-size: 14px;
        }
      }

      .buttons {
        display: inline-flex;
        gap: 8px;

        button {
          text-transform: none;
          letter-spacing: normal;
          font-weight: 500;
        }
      }
    }
  }
}