@import "Styles/colors.module.scss";

.findestButton {
    height: 32px;
    padding: 0 16px;
    border-radius: 0.25rem;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.246px;
    cursor: pointer;
    border: 0;
    font-family: 'Roboto', sans-serif;
    outline: none;
    background-color: $primaryButtonColorDefault;
    color: $white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    white-space: nowrap;

    &:hover {
        background-color: $primaryButtonColorHover;
    }

    > svg {
        margin-right: 10px;
    }

    &.disabled {
        opacity: .15;
        cursor: not-allowed;
    }

    &.colorless {
        color: $findest-text;
        background-color: white;
    
        &:hover {
            box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.05);
            border-color: $findest-text;
        }
    }
    &.cancel {
        color: $primaryButtonColorDefault;
        background-color: white;
        &:hover {
            background-color: $primaryButtonColorDisabled;
        }
    }

    &.secondary {
        color: $main-text-color;
        background-color: $secondaryButtonColorDefault;
        &.disabled {
            opacity: .3;
        }
        &:hover {
            background-color: $secondaryButtonColorHover;
        }
    }

    &.tertiary {
        color: $main-text-color;
        background-color: white;
        &:hover {
            background-color: $secondaryButtonColorDefault;
        }
    }

    &.iconOnRight {
        flex-direction: row;
        svg {
            margin: 0 0 0 10px;
        }
    }
    &.noWrap {
        white-space: nowrap;
    }
}