@import "./colors.module.scss";
  
@mixin customScrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $findest-gray;
            border-radius: 6px;
            &:hover {
                background: $findest-dark-gray;
            }
        }
    }
}
