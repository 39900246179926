@import "Styles/colors.module.scss";

.text {
    color: $main-text-color;
    line-height: 24px;
    letter-spacing: 0.496px;
    display: -webkit-box;
    line-clamp: 10;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.unclampText {
        line-clamp: unset;
        -webkit-line-clamp: unset;
        overflow: visible;
    }
}
.readMore {
    color: $findest-blue;
    cursor: pointer;
    font-size: 14px;
    display: inline;
    margin-right: 6px;
    svg {
        margin-left: 4px;
        font-size: 12px;
    }
}