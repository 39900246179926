@import "Styles/colors.module.scss";

.tooltip {
    background-color: $findest-dark-gray !important;
    color: white !important;
    padding: 4px 12px !important;
    z-index: 99999 !important;
    overflow: visible !important;
    max-width: 700px !important;
    &:after {
        content: " ";
        display: block;
        position: absolute;
        height: 10px;
        width: 100%;
        bottom: -10px;
        left: 0;
        right: 0;
    }

    &:before {
        content: " ";
        display: block;
        position: absolute;
        height: 10px;
        width: 100%;
        top: -10px;
        left: 0;
        right: 0;
    }
}

.tooltipSource {
    color: $findest-purple;
    font-weight: bold;
}
