// general
$main-background-color: #395FA7;
$main-text-color: #252525;
$light-text-color: #252525a6;
$sidebar-hover-background-color: #758FC2;
$group-chip-color: #A1E6AD;
$highlightBackgroundHoverColor: #F7F8FA;

// WHITE
$white: #FFFFFF;
$white-30: rgba(255, 255, 255, 0.3);

// GREEN
$main-light-green: #36BF31;
$main-dark-green: #30A32C;

// RED
$main-light-red: #F05F5F;
$main-dark-red: #C33939;
$notification-red: #F24343;
$notification-red-25: #FCD0D0;

// BLACK
$main-black: #252525;
$black: #000;

// PURPLE
$main-purple: #9F69D5;
$main-purple-50: #9F69D550;
$main-purple-hover: #8157AC;
$main-purple-disabled: #EBE2F3;
$purple-2: #7B35C1;
$purple-3: #9f67d7;

// GRAY
$light-gray: #F2F4F8;
$light-gray-2: #afb8c3;
$light-gray-3: #00317117;
$light-gray-4: #F2F2F2;
$main-gray: #F2F4F8;
$main-gray-transparent: #00317105;

// PINK
$light-pink: #E8BFDC;

// BLUE
$navy-blue: #003171;
$light-blue: #EBF5FF;
// maturity radar steps
$maturityRadarStep-1: #DEF2FC;
$maturityRadarStep-2: #C2E8F9;
$maturityRadarStep-3: #ABDFF7;
$maturityRadarStep-4: #5EBBED;

// YELLOW
$findest-yellow: #FFCC00;

// buttons
$primaryButtonColorDefault: #007AFF;
$primaryButtonColorHover: #006DE3;
$primaryButtonColorDisabled: #D9EBFF;
$secondaryButtonColorDefault: #F2F4F8;
$secondaryButtonColorHover: #BFDDFF;
$secondaryButtonColorHoverLight: #E2F0FF;
$secondaryButtonColorSelected: #66AFFF;
$buttonShadowColor: #00000025;

// inputs
$inputFocusBorderColor: #007AFF;

// findest
$findest-blue: #007AFF;
$findest-light-blue: #66AFFF;
$findest-primary-purple: #A30076;
$findest-purple: #5856D6;
$findest-gray: #CCCCCC;
$findest-dark-gray: #757575;
$findest-text: #212121;
$light-background-color: #F2F2F2;

// menu colors
$findest-background-01: #FCFCFD;
$findest-background-02: #FAFBFC;
$findest-background-03: #F7F8FA;
$findest-background-04: #F2F4F7;
$findest-background-05: #EFF1F6;

// login screen colors
$loginbox-background-color: #EDF5FF;
$loginbox-box-shadow-color: #b7b8d8;
$login-title-color: #003171;
$login-next-button-background-color: #31BE6C;
$login-next-button-background-hover-color: #109145;
$login-magiclink-button-background-color: #7D31BE;
$login-magiclink-button-background-hover-color: #500f88;
$login-go-back-color: #707070;
$login-go-back-arrow-color: #9092A3;

// Object type colors
$study-count-color: #5856D6;
$entity-count-color: #00ADEF;
$document-count-color: #003171;
$highlight-count-color: #FF9500;
$highlight-count-color-50: #FF950050;
$attachment-count-color: #36BF31;
$query-count-color: #A30076;

$entity-color-10: #E5F7FE;
$study-color-10: #EEEEFB;
$table-header: #EBEFF4;
$table-header-hover: #E0E6EE;

//loading, progress indicators
$file-upload-track-color: #E0E0E0;
$file-upload-progress-color: #58B4F0;

// box shadows
$actions-box-shadow-color: rgba(0, 0, 0, 0.16);
$general-shadow-color: rgba(0, 0, 0, 0.16);

// new badge
$new-badge-background-color: #b55efe;

// more button
$moreIconColor: #5F6367;

// border
$labelBorderColor: #D9E0EA;

// ToggleButton
$toggleButtonBlue: #007AFF;
$toggleButtonBlueShading: #E5F1FF;
