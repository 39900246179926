@import "Styles/colors.module.scss";

.searchBarContainer {
    display: flex;
    margin-bottom: 16px;
}

.dataContainer {
    .footer {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        .paginationDetails {
            display: flex;
            align-items: center;
            font-size: 14px;

            .rowsPerPageDropdownButton {
                flex-direction: row;
                margin: 0 8px;
                svg {
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}

.filterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 11px 0;
    .searchTextbox {
        width: 182px;
    }
    .toolbarButtons {
        display: flex;
        margin-left: 16px;
        .columnSelectionButtonContainer {
            margin-right: 8px;
        }
        button {
            padding: 0 10px;
            flex-direction: row;

            svg:last-child {
                margin: 0;
            }
        }
    }
}

.dataTable {
    border-collapse: collapse;
    width: 100%;

    th {
        padding: 0.75rem;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;


        .columnTitleContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .sortIcon {
                margin-left: 8px;
                font-size: 14px;
                color: $findest-dark-gray;
                cursor: pointer;
                &.active {
                    color: $findest-purple;
                }
            }
        }
    }

    td, th {
        border: 1px solid $findest-gray;
        padding: 8px;

        .scoreColumnHeader {
            display: flex;
            justify-content: center;
        }
        .dateColumnHeader {
            white-space: nowrap;
        }
        a {
            color: $findest-blue;
        }
        .similarsButton {
            border: 1px solid $findest-gray;
            margin-top: 10px;
            text-transform: none;
            
        }
    }
    tr {
        &:hover {
            td {
                background-color: $light-gray;
            }
        }
    }
    .noResultsFound {
        text-align: center;
        font-size: 14px;
    }
}

.loadingContainer {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
