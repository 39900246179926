@import 'Styles/colors.module.scss';

.tabs {
    display: flex;
    position: relative;

    .tab {
        height: 32px !important;
        width: fit-content;
        background-color: white;
        border: 1px solid $findest-gray;
        display: flex;
        position: relative;
        align-items: flex-end;
        justify-content: center;
        z-index: 1;
        height: 40px;
        color: $black;
        text-transform: unset;
        padding: 9px 13px;
        align-items: center;
        cursor: pointer;

        &:first-child {
            border-right: none !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        &:last-child {
            border-left: none !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        .tabIcon {
            font-size: 12px;
            height: 20px;
            display: flex;
            align-items: center;
            margin-right: 6px;
        }

        &.selected {
            background-color: $primaryButtonColorDefault;
            border: 1px solid $primaryButtonColorDefault;
            color: white;
            cursor: default;

            h4 {
                font-weight: 500;
            }
        }

        &.disabled {
            color: $findest-gray;
            cursor: not-allowed;
            
            h4 {
                font-weight: 400;
                white-space: nowrap;
            }
        }
        &:not(.selected):not(.disabled):hover {
            color: $main-text-color;
        }
    }
}