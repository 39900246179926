@import "Styles/colors.module.scss";

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    .pageItem {
        padding: 6px 12px;
        line-height: 1.428571429;
        position: relative;
        float: left;
        margin-left: -1px;
        color: $primaryButtonColorDefault;
        text-decoration: none;
        background-color: white;
        border: 1px solid $light-gray;
        user-select: none;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:not(.disabled):hover {
            color: $primaryButtonColorHover;
            background-color: $light-gray;
            border-color: $light-gray;
        }
 
        &.active {
            color: white;
            background-color: $primaryButtonColorDefault;
            border-color: $primaryButtonColorDefault;
            cursor: default;
            pointer-events: none;
        }
        &.disabled {
            cursor: default;
            &.dots {
                pointer-events: none;
            }

            &.chevron {
                cursor: not-allowed;
                svg {
                    opacity: .2;
                }
            }
        }
    }
}