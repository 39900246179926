.container {
    padding: 0 10px 10px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 950px) {
    .container {
        width: 928px;
    }
}

@media (min-width: 1440px) {
    .container {
        width: 1170px;
        &.questionAnswering {
            width: 948px;
        }
    }
}

.mainPageContent {
    .logoSectionContainer {
        text-align: center;
        .logoContainer {
            margin: 32px 0 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .logoImage {
                height: 32px;
                margin-right: 8px;
            }
            .logoText {
                font-weight: 500;
                font-size: 24px;
            }
        }
    }

    .doubleTabsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 1rem;
        margin: 16px auto;
        max-width: 928px;

        h4 {
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 4px;
        }
    }
}

.searchBarContainer {
    display: flex;
    margin: 16px auto;
    max-width: 928px;
    .searchBar {
        input {
            padding: 10px 32px 10px 56px;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .searchBarIconContainer {
            height: 38px;
        }

    }
    .searchButton {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        height: auto;
    }
}
