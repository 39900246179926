@import "Styles/colors.module.scss";

.option {
    padding: 5px 10px;
    cursor: pointer;
    &.selected {
        background-color: $light-gray;
        cursor: default;
    }
    &:not(.selected):hover {
        background-color: $light-gray;
    }
}
